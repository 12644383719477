import { useContext, useState, useEffect } from "react";
import "../assets/scss/common.scss";
import "../assets/scss/pages/contactUs.scss";

import DashboardSidebar from "../components/sections/dashboard/sidebar";
import Topbar from "../components/sections/dashboard/topbar";

import { Image } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';

import email from "../assets/images/dashboard/email.svg";
import contactUs from "../assets/images/dashboard/contact-us-img.svg";

import Btn from "../components/templates/button";
import AuthContext from "../components/shared/AuthContext";
import Loading from "../components/shared/Loading";
import TurnstileWidget from "../components/shared/TurnstileWidget";

const ContactUs = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [userFirstName, setUserFirstName] = useState(null);
  const [messageField, setMessageField] = useState({});
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const { getUserDetails, submitMessage } = useContext(AuthContext);

  const notifyError = (errorMessage) => toast.error(errorMessage);
  const notifySuccess = (successMessage) => toast.success(successMessage);

  useEffect(() => {
    async function getDetails() {
      try {
        setLoading(true);
        document.body.classList.add('disable-scroll');

        const userDetails = await getUserDetails();

        setUserFirstName(userDetails.data.first_name);
      } catch (error) {
        notifyError("Error fetching user info. Please try again later.");
      } finally {
        setLoading(false);
        document.body.classList.remove('disable-scroll');
      }
    }

    getDetails();
  }, []);

  const handleShow = () => {
    setShowSidebar(!showSidebar);
  };

  const handleMessageSubmit = async (e) => {
    const formField = {...messageField};
    
    try {
        e.preventDefault();
        
        if (handleMessageValidation()) {
          const message = formField['message'];

          const payload = {
            message: message,
            is_captcha_verified: isCaptchaVerified
          };

          setLoading(true);
          setDisableButton(true);
          document.body.classList.add('disable-scroll');

          await submitMessage(payload);

          notifySuccess("Message successfuly sent!");
          setMessageField("");
          setIsCaptchaVerified(false);
        }
    } catch (e) {
      if (e === null || e.code === "ERR_NETWORK") {
        notifyError("Website is under maintenance. Please try again later.");
      } else {
        const responseStatus = e.response?.status;

        if (responseStatus === 401) {
          notifyError("Unauthorized. Please try again.");
        } else if (responseStatus === 400) {
          notifyError("Invalid characters detected in your message. Please do not use special characters.");
        } else if (responseStatus === 413) {
          notifyError("Your message is too large.");
        } else if (responseStatus === 429) {
          notifyError("Message limit reached. Please try again later.");
        } else if (responseStatus === 403) {
          notifyError("Turnstile is not validate. Please comlpete verification before a sending message.");
        } else {
          notifyError("Something went wrong. Please try again later.");
        }
      }
    } finally {
      setLoading(false);
      setDisableButton(true);
      document.body.classList.remove('disable-scroll');

      setTimeout(() => {
        setDisableButton(false);
      }, 2000);
    }
  }

  const handleMessageValidation = () => {
    var specialCharacterRegex = /[#$%^&@*()_+\-=\[\]{};:"\\|<>\/]+/;

    const formFields = {...messageField};
    let formIsValid = true;

    if (!formFields['message']) {
        formIsValid = false;
        notifyError("Message can't be empty!");
    } else if (specialCharacterRegex.test(formFields['message'])) {
        formIsValid = false;
        notifyError("Message has invalid characters!");
    } else if (formFields['message'].length > 500) {
        formIsValid = false;
        notifyError("Message is too long. Please use less than 500 characters.");
    } else if (!isCaptchaVerified) {
      formIsValid = false;
      notifyError("Captcha not validated. Please try again.");
    }

    return formIsValid;
  };

  const messageChange = (field, value) => {
    setMessageField({
      ...messageField,
      [field]: value
    });
  };

  const handleTurnstileChange = (value) => {
    setIsCaptchaVerified(value);
  }

  return (
    <div className="dashboard-body">
      <div className="d-lg-flex">
        <div>
          <DashboardSidebar
            active="contact-us"
            showSidebar={showSidebar}
            handleShow={handleShow}
          />
        </div>
        <div className="w-100">
          <Topbar handleShow={handleShow} firstName={userFirstName} />
          <div className="contact-us">
            <div className="contact-us-content">
              <div className="contact-detail">
                <div className="heading">
                  <h3 className="heading-3 heading-3-semi-bold">
                    Contact Details
                  </h3>
                  <p className="paragraph-main paragraph-main-regular">
                    To report any bugs or submit any inquires, please email us
                    directly or fill out the form.
                  </p>
                </div>
                <div className="email">
                  <div className="icon">
                    <Image src={email} />
                  </div>
                  <div className="text">
                    <h6 className="heading-6 heading-6-semi-bold">Email</h6>
                    <p className="paragraph-main paragraph-main-regular">
                      support@gateteck.com
                    </p>
                  </div>
                </div>
                <div className="contact-img">
                  <Image src={contactUs} />
                </div>
              </div>
              <div className="message">
                <div className="heading">
                  <h3 className="heading-3 heading-3-semi-bold">
                    Need assistance or have any feedback?
                  </h3>
                  <p className="paragraph-main paragraph-main-regular">
                    Support requests or feedback can be sent out using the form below.
                    Please allow up to 3 business days for us to get back to
                    you. We take your requests seriously and appreciate your
                    patience. Thank you for choosing GateTeck!
                  </p>
                </div>
                <form onSubmit={handleMessageSubmit}>
                  <div className="message-textarea">
                    <h6 className="heading">Message</h6>
                    <textarea
                      placeholder="Message"
                      className="textarea"
                      onChange={e => messageChange('message', e.target.value)} 
                      value={messageField['message']}
                    ></textarea>
                  </div>
                  <TurnstileWidget onValueChange={handleTurnstileChange}></TurnstileWidget>
                  <div className="submit-btn">
                    <div>
                      <Btn text="Submit Message" class="primary-btn" type="submit" disableButton={disableButton}/>
                    </div>
                  </div>                  
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {loading && <Loading></Loading>}
    </div>
  );
};

export default ContactUs;
