import { Image, Modal } from "react-bootstrap";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import closeBtn from "../../../assets/images/dashboard/close.svg";
import Btn from "../../templates/button";

import { toast } from 'react-toastify';

import AuthContext from "../../shared/AuthContext";
import Loading from "../../shared/Loading";

import "../../../assets/scss/components/section/dashboard/deletePropertyModal.scss";

const DeletePropertyModal = (props) => {
    const [disableButton, setDisableButton] = useState(false);
    const [loading, setLoading] = useState(false);

    const {removeProperty} = useContext(AuthContext);
    
    const notifyError = (errorMessage) => toast.error(errorMessage);
    const navigate = useNavigate();

    const deleteProperty = async (e) => {
      try {
        setLoading(true);
        setDisableButton(true);

        document.body.classList.add('disable-scroll');

        const payload = {
            callbox_phone: props.selectedProperty.callbox_phone
        };

        await removeProperty(payload);

        navigate("/properties?success=true&op=dp");

      } catch (error) {
        if (error === null || error.code === "ERR_NETWORK") {
          notifyError("Something went wrong. Please try again later.");
        } else {
          let responseStatus = error.response?.status;

          if (responseStatus === 401) {
            notifyError("Unauthorized. Please try again.");
          } else if (responseStatus === 406) {
            notifyError("You have reached your delete limit for this week. Please contact Gateteck support.");
          } else if (responseStatus === 500 || responseStatus === 404) {
            notifyError("Unable to delete property. Please try again later.");
          } else {
            notifyError("Something went wrong. Please try again later.");
          }
        }
      } finally {
        setTimeout(() => {            
          setLoading(false);
          setDisableButton(false);
          document.body.classList.remove('disable-scroll');
        }, 1000);
      }
    }

  return (
    <div>
      <Modal
        className="delete-property-modal"
        show={props.showModal}
        onHide={props.closeModal}
      >
        <div className="">
          <div className="d-flex justify-content-end" closeButton>
            <Image
              src={closeBtn}
              onClick={props.closeModal}
              className="close-btn"
            />
          </div>
          <h3 className="modal-heading">Delete {props.selectedProperty.property_name}</h3>
          <div>
            <p className="modal-text mb-4">
              Are you sure you want to delete your property? You can only delete up to <span>{props.propertyDeleteLimit - 1}</span> per week.
            </p>
            <Btn class="pricing-btn" text="Delete Property" disabled={disableButton} onClick={deleteProperty} />
            <p className="modal-text mt-4 mb-0"><strong>You have {props.propertyDeleteLimit - props.propertyDeleteCount} {(props.propertyDeleteLimit - props.propertyDeleteCount) === 1 ? "delete" : "deletes"} remaining.</strong></p>
          </div>
        </div>
      </Modal>
      {loading && <Loading></Loading>}
    </div>
  );
};

export default DeletePropertyModal;
