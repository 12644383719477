import "../assets/scss/common.scss";
import "../assets/scss/components/templates/_subscription-verification-common.scss";

import logo from "../assets/images/dashboard/payment-success-logo.svg";
import { Image } from "react-bootstrap";
import Btn from "../components/templates/button";
import Topbar from "../components/sections/dashboard/topbar";
import Loading from "../components/shared/Loading";
import { useContext, useState, useEffect } from "react";
import AuthContext from "../components/shared/AuthContext";
import { ToastContainer, toast } from 'react-toastify';

const EmailVerification = () => {
  const { sendVerificationToken, verifyToken, getUserDetails } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [userFirstName, setUserFirstName] = useState(null);

  const notifyError = (errorMessage) => toast.error(errorMessage);
  const notifySuccess = (successMessage) => toast.success(successMessage);

  useEffect(() => {
    async function getDetails() {
      try {
        setLoading(true);
        document.body.classList.add('disable-scroll');

        const details = await getUserDetails();
        
        setUserFirstName(details.data.first_name);
      } catch (error) {
        console.log("Error getting details.");
      } finally {
        setLoading(false);
        document.body.classList.remove('disable-scroll');
      }
    }

    getDetails();  

    const urlParams = new URLSearchParams(window.location.search);
    const uuid = urlParams.get('uuid');

    if (!uuid || uuid.length === 0) {
        ; // do nothing
    } else {
        checkToken(uuid);
    }
  }, []);

  const checkToken = async (uuid) => {
    try {
      setLoading(true);
      setDisableButton(true);
      document.body.classList.add('disable-scroll');

      await verifyToken(uuid);
      window.location.reload();
    } catch (error) {
      if (error === null || !error.response || error.code === "ERR_NETWORK") {
          notifyError("Something went wrong. Please try again later.");
      } else {
        const responseStatus = error.response?.status;

        if (responseStatus === 401) {
          console.log("Unauthorized. Please try again.");
          window.location.reload();
        } else if (responseStatus === 500 || responseStatus === 404) {
          notifyError("Something went wrong. Please try again later.");  
        } else if (responseStatus === 429) {
          notifyError("Please wait 5 minutes before requesting another verification link.");
        } else if (responseStatus === 403) {
          notifyError("Token is incorrect or expired. Please request a new one.");            
        } else {
          notifyError("Something went wrong. Please try again later.");
        }
      }
    } finally {
      setLoading(false);
      setDisableButton(true);
      document.body.classList.remove('disable-scroll');
      
      setTimeout(() => {
          setDisableButton(false);
      }, 2000);
    }
  }

  const sendEmail = async (e) => {
    try {
      e.preventDefault();

      setLoading(true);
      setDisableButton(true);
      document.body.classList.add('disable-scroll');

      await sendVerificationToken();
      notifySuccess("Success! Please check your email for a verification link.");
    } catch (error) {
      if (error === null || error.code === "ERR_NETWORK") {
        notifyError("Something went wrong. Please try again later.");
    } else {
        const responseStatus = error.response?.status;

        if (responseStatus === 401) {
          console.log("Unauthorized. Please try again.");
          window.location.reload();
        } else if (responseStatus === 500 || responseStatus === 404) {
          notifyError("Something went wrong. Please try again later.");
        } else if (responseStatus === 429) {
          notifyError("Please wait 5 minutes before requesting another verification link.");
        } else {
          notifyError("Something went wrong. Please try again later.")
        }
      }
    } finally {
      setLoading(false);
      setDisableButton(true);
      
      document.body.classList.remove('disable-scroll');

      setTimeout(() => {
        setDisableButton(false);
      }, 2000);
    }
  }

  return (
    <div className="dashboard-body email-verification">
      <Topbar type="verification" firstName={userFirstName} />
      <div className="subscription-verification-common">
        <div className="logo">
          <Image src={logo} width="225px" />
        </div>
        <div className="success-verification-box verification-box">
          <h3 className="heading-4 heading-3-semi-bold">
            Please Verify your Email Address
          </h3>
          <p className="paragraph-main paragraph-main-regular">
            Thank you for signing up! To complete your registration, we need to
            verify your account.
          </p>
          <p className="paragraph-main paragraph-main-regular mb-4">
            Please click the button below to receive a verification link. If you
            dont receive the email please check your spam folder.
          </p>
          <div className="verification-btn">
            <Btn disabled={disableButton} class="primary-btn" text="Verify your Email Address" onClick={sendEmail} />
          </div>
        </div>
      </div>
      <ToastContainer />
      {loading && <Loading></Loading>}
    </div>
  );
};

export default EmailVerification;
