import { Container, Row, Col, Image } from "react-bootstrap";
import img1 from "../../../assets/images/feature-1.svg";
import img3 from "../../../assets/images/feature-3.svg";
import img2New from "../../../assets/images/feature-2-new.svg";
import check from "../../../assets/images/check-with-bg.svg";
import Btn from "../../templates/button";

const Features = () => {
  return (
    <div className="features-section" id="features">
      <Container>
        <Row className="text-center text-lg-start">
          <div className="heading text-center mb-4">
            <h1 className="heading-1 heading-1-semi-bold">Our Features</h1>
          </div>
          <div>
            <Row className="align-items-center mb-5">
              <Col lg={6}>
                <div className="feature-img mb-4 mb-lg-0">
                  <Image src={img1} className="img-fluid" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="features-content ms-lg-5">
                  <div className="heading">
                    <h2 className="heading-2 heading-2-semi-bold mb-3">
                      Automated Call Routing and Verification
                    </h2>
                    <p className="paragraph-medium paragraph-medium-regular mb-3">
                      Incoming guest dial in calls are routed through GateTeck
                      where we automatically answer and validate the dial in
                      attempt. Authorized calls prompt GateTeck to dial the
                      required digits, automatically granting gate entry without
                      requiring the property owner to manually answer the call.
                    </p>
                    <a href="/signup">
                      <Btn text="Automate Your Entry Today" class="primary-graident-btn" />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center mb-5 flex-column-reverse flex-lg-row">
              <Col lg={6} className="order-2 order-lg-1">
                <div className="features-content">
                  <div className="heading">
                    <h2 className="heading-2 heading-2-semi-bold mb-3">
                      Authorized and Secure Entry Control
                    </h2>
                    <p className="paragraph-medium paragraph-medium-regular mb-5">
                      Take full control of property access with GateTeck. Our system 
                      ensures only incoming calls from your property's call box phone 
                      number are recognized, blocking unauthorized callers. With 
                      features like TimeLock, you can schedule specific days and times 
                      for entry, while enjoying a seamless, secure, and customizable 
                      experience tailored to your needs.
                    </p>
                    <ul className="points">
                      <li>
                        <img src={check} className="check"/>
                        <div className="info">
                          <h5 className="heading"> 
                            Receive Your Dedicated Phone Number
                          </h5>
                          <p>
                            We'll provide you with a dedicated phone number. This number will be 
                            called by the call box in place of your own phone number.
                          </p>
                        </div>
                      </li>
                      <li>
                        <img src={check} className="check"/>
                        <div className="info">
                          <h5 className="heading">
                            Set Time Windows
                          </h5>
                          <p>
                            With our advanced TimeLock feature, users can 
                            specify exact start and end times for access or 
                            enable 24/7 entry with a single click.
                          </p>
                        </div>
                      </li>
                      <li>
                        <img src={check} className="check"/>
                        <div className="info">
                          <h5 className="heading">
                            Enhanced Security
                          </h5>
                          <p>
                            Restrict unauthorized access outside of designated hours, giving you peace of mind.
                          </p>
                        </div>
                      </li>
                      <li>
                        <img src={check} className="check"/>
                        <div className="info">
                          <h5 className="heading">
                            Effortless Management
                          </h5>
                          <p>
                            Easily update or delete TimeLock schedules at any time to adapt to changing needs.
                          </p>
                        </div>
                      </li>
                    </ul>
                    <a href="/signup" className="d-xl-block d-lg-block d-sm-none d-none">
                      <Btn text="Control & Secure Your Entry" class="primary-graident-btn" />
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg={6} className="order-1 order-lg-2">
                <div className="feature-img mb-4 mb-lg-0">
                  <Image src={img2New} className="img-fluid" />
                  <a href="/signup" className="d-flex justify-content-center mt-3">
                    <Btn text="Secure Your Gate Now" class="d-lg-none d-xl-none d-sm-block primary-graident-btn"/>
                  </a>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col lg={6}>
                <div className="feature-img mb-lg-0">
                  <Image src={img3} className="img-fluid" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="features-content ms-lg-5">
                  <div className="heading mt-lg-0 mt-md-0 mt-4">
                    <h2 className="heading-2 heading-2-semi-bold mb-3">
                      Convenience for Property Owners and Managers
                    </h2>
                    <p className="paragraph-medium paragraph-medium-regular mb-3">
                      Free yourself from the need to answer gate-entry calls and
                      allow guests to gain entry seamlessly. The system is
                      particularly beneficial for property owners and managers,
                      such as those managing rentals or AirBnb properties.
                    </p>
                    <a href="/signup">
                      <Btn text="Start Saving Your Time" class="primary-graident-btn" />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Features;
