import { useEffect, useState, useContext } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useLocation  } from "react-router-dom";

import "../assets/scss/common.scss";
import "../assets/scss/pages/addTimelock.scss";
import "../assets/scss/pages/signup.scss";

import DashboardSidebar from "../components/sections/dashboard/sidebar";
import Topbar from "../components/sections/dashboard/topbar";
import GoBackButton from "../components/templates/goBackBtn";
import AuthContext from "../components/shared/AuthContext";
import Loading from "../components/shared/Loading";

const AddTimelock = ({ userFirstName }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [selectedTimelockDays, setSelectedTimelockDays] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [disableBackButton, setDisableBackButton] = useState(false);
  const [timeRange, setTimeRange] = useState({ from: "13:00", to: "18:00" });
  const [isMobile, setIsMobile] = useState(false);
  const [allDay, setAllDay] = useState(false);
  const [propertyName, setPropertyName] = useState("Property");
  const [timezone, setTimezone] = useState("");
  
  const { getSingleProperty, addTimelock } = useContext(AuthContext);

  const location = useLocation();
  const { callboxPhone } = location.state || {};

  const notifyError = (errorMessage) => toast.error(errorMessage);
  const navigate = useNavigate();

  useEffect(() => {
    if (!callboxPhone) {
      navigate("/properties");
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 575);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    async function getPropertyDetails() {
      try {
        setLoading(true);
        setDisableButton(true);

        document.body.classList.add('disable-scroll');

        const getSinglePropertyResponse = await getSingleProperty(callboxPhone);
        
        setPropertyName(getSinglePropertyResponse?.data?.property?.property_name);
        setTimezone(getSinglePropertyResponse?.data?.property?.property_tz);
      } catch (e) {
        setLoading(false);
        setDisableButton(false);

        document.body.classList.remove('disable-scroll');

        navigate("/properties?error=e500&op=at");
      } finally {
        setLoading(false);
        setDisableButton(false);

        document.body.classList.remove('disable-scroll');
      }
    }

    getPropertyDetails();
  }, []);

  const handleChange = (event) => {
    const { value } = event.target;
    setSelectedTimelockDays(prev =>
      prev.includes(value) ? prev.filter(type => type !== value) : [...prev, value]
    );
  };

  const handleAllDayChange = (event) => {
    setAllDay(!allDay);
    setTimeRange({ from: "00:00", to: "23:59" });
  }

  const handleShow = () => {
    setShowSidebar(!showSidebar);
  };

  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    setTimeRange((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCreateTimelockSubmit = async (e) => {
    try {
      e.preventDefault();
      
      if (handleCreateTimelockValidation()) {
        const payload = {
          callbox_phone: callboxPhone,
          timelock_days: selectedTimelockDays,
          from_time: timeRange.from,
          to_time: timeRange.to,
          is_all_day: allDay
        };

        setLoading(true);
        setDisableButton(true);
        setDisableBackButton(true);

        document.body.classList.add('disable-scroll');

        await addTimelock(payload);
        navigate("/properties?success=true");
      }
    } catch (e) {
      if (e === null || e.code === "ERR_NETWORK") {
        notifyError("Something went wrong. Please try again later.");
      } else {
        let responseStatus = e.response.status;

        if (responseStatus === 401) {
          notifyError("Unauthorized. Please try again.");
        } else if (responseStatus === 417) {
          notifyError("Start time can't be bigger than your end time.");
        } else if (responseStatus === 409) {
          notifyError("Start time can't be the same as your end time.");
        } else {
          notifyError("Something went wrong. Please try again later.");
        }
      }
    } finally {
      setLoading(false);
      setDisableButton(true);
      setDisableBackButton(false);

      document.body.classList.remove('disable-scroll');

      setTimeout(() => {
        setDisableButton(false);
      }, 1500);
    }
  }

  const handleCreateTimelockValidation = () => {
    let formIsValid = true;

    if (!selectedTimelockDays || selectedTimelockDays.length === 0) {
        formIsValid = false;
        notifyError("At least one property date must be selected.");
    } else if (!timeRange.from || timeRange.from.length === 0) {
        formIsValid = false;
        notifyError("A from time must be specified.");
    } else if (!timeRange.to || timeRange.from.to === 0) {
        formIsValid = false;
        notifyError("A to time must be specified.");
    } 

    return formIsValid;
  };

  return (
    <div className="dashboard-body">
      <div className="d-lg-flex">
        <div>
          <DashboardSidebar
            active="properties"
            showSidebar={showSidebar}
            handleShow={handleShow}
          />
        </div>
        <div className="w-100">
          <Topbar handleShow={handleShow} firstName={userFirstName}/>
          <div className="add-timelock-main">
            <h5 className="heading-5 heading-5-semi-bold">Add TimeLock</h5>
            <GoBackButton isDisabled={disableBackButton}/>
            <div className="add-timelock-box">
              <div className="add-timelock-content">
                <Form onSubmit={handleCreateTimelockSubmit}>
                  <h3 className="heading-4 heading-4-semi-bold text-center mb-3" id="timelock-title">
                    {propertyName}
                  </h3>
                  <h6 className="heading-6 heading-6-semi-bold" id="timelock-subtitle">
                    Gate Entry Dates
                  </h6>
                  <p className="paragraph-small paragraph-small-regular mt-0 ml-0 signup-text font-weight-light text-black-50" id="entry-dates-sub">Please select the days that gate entry should be permitted.</p>
                  <Row xs={0} sm={2} className="checkbox-inputs">
                    <Col xs={0} sm={4} md={4}>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="input-checkbox"
                          value="monday"
                          checked={selectedTimelockDays.includes("monday")}
                          onChange={handleChange}
                        />
                        Monday
                      </label>
                    </Col>
                    <Col xs={0} sm={4} md={4}>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="input-checkbox"
                          value="tuesday"
                          checked={selectedTimelockDays.includes("tuesday")}
                          onChange={handleChange}
                        />
                        Tuesday
                      </label>
                    </Col>
                    <Col xs={0} sm={4} md={4}>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="input-checkbox"
                          value="wednesday"
                          checked={selectedTimelockDays.includes("wednesday")}
                          onChange={handleChange}
                        />
                        Wednesday
                      </label>
                    </Col>
                  </Row>
                  <Row xs={0} sm={2} className="checkbox-inputs">
                    <Col xs={0} sm={4} md={4}>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="input-checkbox"
                          value="thursday"
                          checked={selectedTimelockDays.includes("thursday")}
                          onChange={handleChange}
                        />
                        Thursday
                      </label>
                    </Col>
                    <Col xs={0} sm={4} md={4}>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="input-checkbox"
                          value="friday"
                          checked={selectedTimelockDays.includes("friday")}
                          onChange={handleChange}
                        />
                        Friday
                      </label>
                    </Col>
                    <Col xs={0} sm={4} md={4}>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="input-checkbox"
                          value="saturday"
                          checked={selectedTimelockDays.includes("saturday")}
                          onChange={handleChange}
                        />
                        Saturday
                      </label>
                    </Col>
                  </Row>
                  <Row className="checkbox-inputs" id="last-checkbox">
                    <Col xs={0} sm={0} md={4}>
                        <label className="checkbox-label">
                          <input
                            type="checkbox"
                            className="input-checkbox"
                            value="sunday"
                            checked={selectedTimelockDays.includes("sunday")}
                            onChange={handleChange}
                          />
                          Sunday
                        </label>
                      </Col>
                  </Row>
                  <h6 className="heading-6 heading-6-semi-bold" id="timelock-subtitle">
                    Gate Entry Time Window
                  </h6>
                  <p className="paragraph-small paragraph-small-regular mt-0 ml-0 signup-text font-weight-light text-black-50" id="time-sub">Please enter the start and end time that gate entry should be permitted in <strong>{ timezone ? (timezone === "America/New York" ? "Eastern" : (timezone === "America/Chicago" ? "Central" : (timezone === "America/Denver" ? "Mountain" : (timezone === "America/Los Angeles" ? "Pacific" : "Eastern")))) : "Eastern" }</strong> standard time.</p>
                  <Row className="time-selector">
                    <Col >
                      <h6 className="heading-6 heading-6-semi-bold" id="timelock-time-label">Start Time</h6>
                      <input
                        type="time"
                        name="from"
                        className="time-input"
                        value={timeRange.from}
                        onChange={handleTimeChange}
                        disabled={allDay}
                      />
                    </Col>
                    <Col >        
                      <h6 className="heading-6 heading-6-semi-bold" id="timelock-time-label">End Time</h6>
                      <input
                        type="time"
                        name="to"
                        className="time-input"
                        value={timeRange.to}
                        onChange={handleTimeChange}
                        disabled={allDay}
                        min={timeRange.from}
                      />
                    </Col>
                  </Row>
                  <Row className="checkbox-inputs mt-3" id="all-day-checkbox">
                    <Col xs={0} sm={0} md={4}>
                      <label className="checkbox-label">
                        <div className="toggle-switch">
                          <input
                            type="checkbox"
                            id="all-day-toggle"
                            className="toggle-switch-checkbox"
                            onChange={handleAllDayChange}
                            checked={allDay}
                          />
                          <label className="toggle-switch-label" htmlFor="all-day-toggle">
                            <span className="toggle-switch-button" />
                          </label>
                        </div>
                        All Day
                      </label>
                    </Col>
                  </Row>
                  <div className="create-property-btn">
                    <Button variant="primary"
                      type="submit"
                      className={isMobile ? "primary-btn mt-3 mb-3 create-prp" :"primary-btn mb-3 create-prp"}
                      disabled={disableButton}
                    >
                      Create TimeLock
                    </Button>
                  </div>
                </Form>
                <p className="paragraph-small paragraph-small-regular mt-2 ml-0 pb-0 mb-0 text-center signup-text font-weight-light text-black-50">TimeLock allows you to specify certain days and times when gate access should be granted.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {loading && <Loading></Loading>}
    </div>
  );
};

export default AddTimelock;
