import { useState, useContext, useEffect } from "react";
import "../assets/scss/common.scss";
import "../assets/scss/pages/subscription.scss";

import DashboardSidebar from "../components/sections/dashboard/sidebar";
import Topbar from "../components/sections/dashboard/topbar";
import { Button, Col, Image, Row } from "react-bootstrap";
import check from "../assets/images/check.svg";
import CustomCancelSubscriptioModal from "../components/sections/dashboard/cancelSubscriptionModal";
import AuthContext from "../components/shared/AuthContext";
import Loading from "../components/shared/Loading";

import { ToastContainer, toast } from 'react-toastify';
import CustomCreateSubscriptioModal from "../components/sections/dashboard/createSubscriptionModal";

const Subscription = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [userFirstName, setUserFirstName] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedTier, setSelectedTier]  = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [isFirstTimeUser, setIsFirstTimeUser] = useState(false);

  const { getUserDetails } = useContext(AuthContext);

  const notifyError = (errorMessage) => toast.error(errorMessage);

  useEffect(() => {
    async function getDetails() {
      try {
        setLoading(true);
        document.body.classList.add('disable-scroll');

        const userDetails = await getUserDetails();
        
        setUserFirstName(userDetails.data.first_name);
        setCurrentPlan(userDetails.data.plan_name);
        setIsFirstTimeUser(!userDetails.data.is_returning_customer);
      } catch (error) {
        notifyError("Error fetching user info. Please try again later.");
      } finally {
        setLoading(false);
        document.body.classList.remove('disable-scroll');
      }
    } 

    getDetails();
  }, []);

  const handleClose = () => {
    setShowModal(false);
    setShowSubscriptionModal(false);
  };

  const handleCancelBasicModal = () => {
    setShowModal(true);
    setSelectedTier("Basic");
    setSelectedPrice("$9.99");
  };

  const handleShowBasicSubscribeModal = () => {
    setSelectedTier("Basic");
    setSelectedPrice("$9.99");

    setShowSubscriptionModal(true);
  }

  const handleShow = () => {
    setShowSidebar(!showSidebar);
  };

  const pricing_content = [
    {
      id: 1,
      name: "Basic Tier",
      description: "Ideal for the everyday property owner",
      list: {
        item1: `Up To 1 Property Supported`,
        item2: "Generic Welcome Message",
        item3: "1 Dedicated Phone Number",
      },
      price: "$9.99",
      btn_text: (currentPlan === "Basic") ? "Cancel Membership" : "$9.99 per Month",
      currentPlan: (currentPlan === "Basic") ? true : false,
      comingSoon: false
    },
    {
      id: 2,
      name: "Host Tier",
      description: "Ideal for casual renters or AirBnb hosts",
      list: {
        item1: "Coming Soon",

        item2: "Coming Soon",

        item3: "Coming Soon",
        item3mobile: "Coming Soon",
      },
      price: "$--.--",
      btn_text: "Coming Soon",
      currentPlan: (currentPlan === "Host") ? true : false,
      comingSoon: true
    },
    {
      id: 3,
      name: "Entrepreneur Tier",
      description: "Ideal for real estate groups or property moguls",
      list: {
        item1: "Coming Soon",

        item2: "Coming Soon",

        item3: "Coming Soon",
      },
      price: "$--.--",
      btn_text: "Coming Soon",
      currentPlan: (currentPlan === "Entrepreneur") ? true : false,
      comingSoon: true
    },
  ];

  return (
    <div className="dashboard-body">
      <div className="d-lg-flex">
        <div>
          <DashboardSidebar
            active="subscription"
            showSidebar={showSidebar}
            handleShow={handleShow}
          />
        </div>
        <div className="w-100">
          <Topbar handleShow={handleShow} firstName={userFirstName} />
          <div style={{ maxWidth: "1200px", margin: "auto" }}>
            <div className="subscription-section" id="pricing">
              <Row>
                <Col className="text-center heading">
                  <h1 className="heading-1 heading-1-semi-bold">
                    Subscription
                  </h1>
                </Col>
              </Row>
              <Row className="subscription-plan-boxes justify-content-center align-item-center">
                {pricing_content.map((content) => (
                  <Col md={4} className="mb-4 mb-lg-0">
                    <div className="pricing-box d-flex justify-content-between flex-column text-center">
                      {content.currentPlan ? (
                        <p className="current-plan">Current Plan</p>
                      ) : (
                        ""
                      )}
                      <div className="content">
                        <h4 className="heading-4 heading-4-semi-bold">
                          {content.name}
                        </h4>
                        <p className="paragraph-main paragraph-main-regular mb-5">
                          {content.description}
                        </p>
                        <div className="listItems text-start">
                          <div className="d-flex align-items-center mb-3">
                            <div className="check me-3 d-flex align-items-center justify-content-center">
                              <Image src={check} />
                            </div>
                            <p className="mb-0 paragraph-small paragraph-small-regular ">
                              {content.list.item1}
                            </p>
                          </div>
                          <div className="d-flex align-items-center mb-3">
                            <div className="check me-3 d-flex align-items-center justify-content-center">
                              <Image src={check} />
                            </div>
                            <p className="mb-0 paragraph-small paragraph-small-regular ">
                              {content.list.item2}
                            </p>
                          </div>
                          <div className="d-flex align-items-center">
                            <div className="check me-3 d-flex align-items-center justify-content-center">
                              <Image src={check} />
                            </div>
                            <p className="mb-0 paragraph-small paragraph-small-regular ">
                              {content.list.item3}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="price">
                        <h1 className="heading-1 heading-1-semi-bold">
                          {content.price}
                        </h1>
                        <Button
                          className={`heading-6 heading-6-bold ${
                            content.comingSoon ? "pricing-btn-light" : (content.currentPlan ? "pricing-btn" : "pricing-btn-blue")
                          }`}
                          onClick={content.comingSoon ? console.log("") : (content.currentPlan ? handleCancelBasicModal : handleShowBasicSubscribeModal) }
                          disabled={content.comingSoon}
                        >
                          {isFirstTimeUser && !content.comingSoon ? "Start Your Free Trial" : content.btn_text}
                        </Button>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
              <CustomCancelSubscriptioModal
                showmodal={showModal}
                closemodal={handleClose}
                modalTitle = {selectedTier}
                modalPrice = {selectedPrice}
              />
              <CustomCreateSubscriptioModal
                showmodal={showSubscriptionModal}
                closemodal ={handleClose}
                modalTitle = {selectedTier}
                modalPrice = {selectedPrice} 
              >
              </CustomCreateSubscriptioModal>
              {loading && <Loading></Loading>}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Subscription;
