import "../../assets/scss/common.scss";
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from './AuthContext';
import AccessDenied from '../../pages/acccessDenied';
import Properties from '../../pages/properties';
import AddProperty from "../../pages/addProperty";
import Loading from './Loading';
import AddTimelock from "../../pages/addTimelock";
import EditTimelock from "../../pages/editTimelock";
import EditProperty from "../../pages/editProperty";

const PropertiesRouter = ({ page }) => {
    const [content, setContent] = useState();
    const [loading, setLoading] = useState(false);

    const { getUserDetails } = useContext(AuthContext);

    useEffect(() => {
        async function getPlan() {
            try {
                document.body.classList.add('disable-scroll');
                setLoading(true);

                const userDetails = await getUserDetails();

                if (userDetails.data.plan_name === "Basic" || userDetails.data.plan_name === "Host" || userDetails.data.plan_name === "Entrepreneur") {
                    if (page === "property-manage")
                        setContent(<Properties userFirstName={userDetails.data.first_name} deletePropertyLimit={userDetails.data.delete_limit}></Properties>);
                    else if (page === "property-add")
                        setContent(<AddProperty userFirstName={userDetails.data.first_name}></AddProperty>)
                    else if (page === "timelock-add")
                        setContent(<AddTimelock userFirstName={userDetails.data.first_name}></AddTimelock>)
                    else if (page === "timelock-edit") {
                        setContent(<EditTimelock userFirstName={userDetails.data.first_name}></EditTimelock>)
                    } else if (page === "property-edit") {
                        setContent(<EditProperty userFirstName={userDetails.data.first_name}></EditProperty>)
                    }
                } else {
                    setContent(<AccessDenied userFirstName={userDetails.data.first_name}></AccessDenied>);
                }
            } catch(e) {
                console.log("Error occurred.");
            } finally {
                document.body.classList.remove('disable-scroll');
                setLoading(false);
            }
        }

        getPlan();
    }, []);  
    
    return (
        <div className="dashboard-body">
            {content}
            {loading && <Loading></Loading>}
        </div>
    );
}

export default PropertiesRouter;