import { Button, Form, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../assets/scss/common.scss";
import "../assets/scss/pages/login.scss";

import back from "../assets/images/back.svg";
import logo from "../assets/images/black-font-logo.svg";
import { useEffect, useContext, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import AuthContext from "../components/shared/AuthContext";
import Loading from "../components/shared/Loading";
import TurnstileWidget from "../components/shared/TurnstileWidget";

const Login = () => {
  const { loginApiCall } = useContext(AuthContext);

  const [loginAccountFields, setLoginAccountFields] = useState({});
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [disableBackButton, setDisableBackButton] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const navigate = useNavigate();
  const notifyError = (errorMessage) => toast.error(errorMessage);

  //-- to change the color of body
  useEffect(() => {
    document.body.style.background = "#F8F9FF";
  }, []);

  const goBack = () => {
    navigate("/");
  };

  const loginAccountChange = (field, value) => {
    setLoginAccountFields({
      ...loginAccountFields,
      [field]: value
    });
  };

  const handleLoginSubmit = async (e) => {
    const formFields = {...loginAccountFields};

    try {
      e.preventDefault();
        
      if (handleLoginValidation()) {
        const userEmail = formFields['email'];
        const userPassword = formFields['password'];

        const payload = {
          email: userEmail,
          password: userPassword,
          is_captcha_verified: isCaptchaVerified
        };

        setLoading(true);
        setDisableButton(true);
        setDisableBackButton(true);

        document.body.classList.add('disable-scroll');

        await loginApiCall(payload);
      }
    } catch (e) {
      if (e === null || e.code === "ERR_NETWORK") {
          notifyError("Website is under maintenance. Please try again later.");
      } else {
          const responseStatus = e.response?.status;

          if (responseStatus === 401) {
            notifyError("Password or email is incorrect.");
          } else if (responseStatus === 500 || responseStatus === 404) {
            notifyError("Website is under maintenance. Please try again later.");
          } else if (responseStatus === 400) {
            notifyError("Invalid request. Please check your input.");
          } else {
            notifyError("Something went wrong. Please try again later.");
          }
      }
    } finally {
      setLoading(false);
      document.body.classList.remove('disable-scroll');
      setDisableButton(true);
      setDisableBackButton(false);

      setTimeout(() => {
        setDisableButton(false);
      }, 2000);
    }
  };

  const handleLoginValidation = () => {
    var specialCharacterRegex = /[!#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;
    var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const formFields = {...loginAccountFields};
    let formIsValid = true;

    if (!formFields['email']) {
        formIsValid = false;
        notifyError("Email can't be empty.");
    } else if (specialCharacterRegex.test(formFields['email'])) {
        formIsValid = false;
        notifyError("Please enter a valid email.");
    } else if (!emailRegex.test(formFields['email'])) {
      formIsValid = false;
      notifyError("Email is invalid.");
    } else if (!formFields["password"]) {
        formIsValid = false;
        notifyError("Password can't be empty.");
    } else if (!isCaptchaVerified) {
      formIsValid = false;
      notifyError("Turnstile is not validated. Please try again.");
    }

    return formIsValid;
  }

  const handleTurnstileChange = (value) => {
    setIsCaptchaVerified(value);
  }

  return (
    <div className="login-box">
      <div className="signup-login-common">
        <div className="back-btn">
          <Button className="back-btn" onClick={goBack} disabled={disableBackButton}>
            <div className="back-icon me-2">
              <Image src={back} />
            </div>
            Back
          </Button>
        </div>
        <Row className="justify-content-center p-2 p-sm-5">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="logo my-4">
              <Image src={logo} width="225px" />
            </div>
            <div className="form px-2">
              <h4 className="heading-4 heading-4-semi-bold text-center mb-4">
                Login to Get Started
              </h4>
              <div>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Control type="email" placeholder="Email Address" onChange={e => loginAccountChange('email', e.target.value)} value={loginAccountFields['email']} />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control type="password" placeholder="Password" onChange={e => loginAccountChange('password', e.target.value)} value={loginAccountFields['password']} />
                  </Form.Group>
                  <div className="forget-password-link d-flex justify-content-end mb-3">
                    <a
                      className="paragraph-sub paragraph-main-regular text-center"
                      href="/forgot-password"
                      style={{ color: "#000" }}
                      disabled={disableButton}
                    >
                      Forgot Password
                    </a>
                  </div>
                  <div className="mb-3 mt-3">
                    <TurnstileWidget onValueChange={handleTurnstileChange}></TurnstileWidget>
                  </div>
                  
                  <Button
                    variant="primary"
                    type="submit"
                    className="primary-btn mb-3"
                    onClick={handleLoginSubmit}
                    disabled={disableButton}
                  >
                    Login
                  </Button>
                  <p className="paragraph-sub paragraph-small-regular text-center">
                    By clicking Login, you agree to our <a href="https://cdn.gateteck.com/gateteck_tos.pdf" target="_blank" rel="noopener noreferrer">Terms of Service</a>, &nbsp; 
                    <a href="https://cdn.gateteck.com/gateteck_disclaimer.pdf" target="_blank" rel="noopener noreferrer">Disclaimers</a>, &nbsp; 
                    <a href="https://cdn.gateteck.com/gateteck-privacy_policy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>, and &nbsp;
                     <a href="https://cdn.gateteck.com/gateteck-cookie_policy.pdf" target="_blank" rel="noopener noreferrer">Cookies Policy</a>.
                  </p>
                </Form>
              </div>
              <p className="paragraph-sub paragraph-main-regular text-center">
                Don't Have Account?
                <a
                  href="/signup"
                  className="link ms-2"
                  style={{ color: "#000" }}
                >
                  Create One
                </a>
              </p>
            </div>
          </div>
        </Row>
      </div>
      <ToastContainer />
      {loading && <Loading></Loading>}
    </div>
  );
};

export default Login;
