import { Nav, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

import dashLogo from "../../../assets/images/dashboard/dashboard-logo.svg";
import mobileLogo from "../../../assets/images/dashboard/sidebar-mobile-logo.svg"
import { ReactComponent as PropertiesLogo } from "../../../assets/images/dashboard/home.svg";
import { ReactComponent as DashboardLogo } from "../../../assets/images/dashboard/dashboard.svg";
import { ReactComponent as SubscriptionLogo } from "../../../assets/images/dashboard/subscription.svg";
import { ReactComponent as ContactLogo } from "../../../assets/images/dashboard/contact.svg";

import closeBtn from "../../../assets/images/dashboard/close.svg";
import { useEffect } from "react";

const DashboardSidebar = ({ active, showSidebar, handleShow }) => {
  useEffect(() => {
    document.body.classList.toggle("sidebar-open", showSidebar);
  }, [showSidebar]);

  return (
    <div
      className={`dashboard-sidebar ${showSidebar ? "sidebar-overlay" : ""} `}
    >
      <div
        className={`sidebar ${showSidebar ? "d-block show" : ""} d-lg-block`}
      >
        <Nav className="flex-column">
          <div
            className={`${
              showSidebar
                ? "d-flex align-items-center justify-content-between mb-3"
                : ""
            }`}
          >
            {!showSidebar && 
              <div className="sidebar-logo text-center mb-5">
                <Image src={dashLogo} />
              </div>
            }
            {showSidebar && 
              <div className="sidebar-logo text-center">
                <Image src={mobileLogo} />
              </div>
            }

            <div className="sidebar-close-button" onClick={handleShow}>
              <Image src={closeBtn} />
            </div>
          </div>
          <Nav.Link
            as={Link}
            className={`nav-link ${active === "dashboard" ? "active" : ""}`}
            to="/dashboard"
          >
            <DashboardLogo className="me-2 icon" />
            Dashboard
          </Nav.Link>
          <Nav.Link
            as={Link}
            className={`nav-link ${active === "properties" ? "active" : ""}`}
            to="/properties"
          >
            <PropertiesLogo className="me-2 icon" />
            Properties
          </Nav.Link>
          <Nav.Link
            as={Link}
            className={`nav-link ${active === "subscription" ? "active" : ""}`}
            to="/subscription"
          >
            <SubscriptionLogo className="me-2 icon" />
            Subscription
          </Nav.Link>
          <Nav.Link
            as={Link}
            className={`nav-link ${active === "contact-us" ? "active" : ""}`}
            to="/contact-us"
          >
            <ContactLogo className="me-2 icon" />
            Contact Us
          </Nav.Link>
        </Nav>
      </div>
    </div>
  );
};

export default DashboardSidebar;
