import { useContext } from "react";
import { toast } from 'react-toastify';
import Turnstile, { useTurnstile } from "react-turnstile";

import AuthContext from "./AuthContext";

const TurnstileWidget = ({ onValueChange }) => {
    const { verifyCaptcha } = useContext(AuthContext);
    const turnstile = useTurnstile();

    const notifyError = (errorMessage) => toast.error(errorMessage);

    return (
        <Turnstile
            sitekey={process.env.REACT_APP_CAPTCHA_PUBLIC_KEY}
            onVerify={async (token) => {
                try {
                    if (!token) {
                        notifyError("Invalid token.");
                        onValueChange(false);
                        turnstile.reset();

                        return;
                    }
                    else {
                        await verifyCaptcha({token});
                        onValueChange(true);
                    }
                } catch (e) {
                    onValueChange(false);

                    if (e === null || e.code === "ERR_NETWORK") {
                        notifyError("Website is under maintenance. Please try again later.");
                    } else {
                        const responseStatus = e.response?.status;

                        if (responseStatus === 500) {
                            notifyError("Website is under maintenance. Please try again later.");
                        } else if (responseStatus === 400) {
                            notifyError("Invalid request.");
                        } else if (responseStatus === 408) {
                            notifyError("Turnstile verification expired. Please try again.");
                            turnstile.reset();
                        } else {
                            notifyError("Error validating Turnstile.");
                        }                        
                    }
                }
            }}
            theme="light"
            className="d-flex justify-content-center mt-2"
        />
    )
}

export default TurnstileWidget;
