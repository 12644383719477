import { useState, useEffect, useContext } from "react";
import { toast, ToastContainer } from 'react-toastify';
import "../assets/scss/common.scss";
import "../assets/scss/pages/properties.scss";
import 'react-toastify/dist/ReactToastify.css';

import DashboardSidebar from "../components/sections/dashboard/sidebar";
import Topbar from "../components/sections/dashboard/topbar"

import totalProperties from "../assets/images/dashboard/total-properties.svg";
import totalVisits from "../assets/images/dashboard/total-visits.svg";
import phone from "../assets/images/dashboard/phone.svg";
import apartment from "../assets/images/dashboard/apartment.svg";
import house from "../assets/images/dashboard/house.svg";
import condo from "../assets/images/dashboard/condo.svg";
import addPropertyIcon from "../assets/images/dashboard/add-property.svg";
import trash from "../assets/images/dashboard/trash.svg";
import timelock from "../assets/images/dashboard/add-timelock.svg";
import dotsMenu from "../assets/images/dashboard/dots-menu-icon.svg";
import addtimelock from "../assets/images/dashboard/edit-timelock.svg";
import editproperty from "../assets/images/dashboard/edit-property.svg";

import { Col, Image, Nav, NavDropdown, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import AuthContext from "../components/shared/AuthContext";
import Loading from "../components/shared/Loading";
import DeletePropertyModal from "../components/sections/dashboard/deletePropertyModal";

const Properties = ({ userFirstName, deletePropertyLimit }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalPropertiesCount, setTotalPropertiesCount] = useState(null);
  const [totalVisitsCount, setTotalVisitsCount] = useState(null);
  const [totalDedicatedPhoneCount, setTotalDedicatedPhoneCount] = useState(null);
  const [existingProperties, setExistingProperties] = useState([]);
  const [currentProperty, setCurrentProperty] = useState({});
  const [currentDeleteCount, setCurrentDeleteCount] = useState(0);
  const [showDeletePropertyModal, setShowDeletePropertyModal] = useState(false);

  const { getDashboardDetails, getExistingProperties } = useContext(AuthContext);

  const navigate = useNavigate();
  const notifyError = (errorMessage) => toast.error(errorMessage);
  const notifySuccess = (successMessage) => toast.success(successMessage);

  useEffect(() => {
    async function getDetails() {
      try {
        setLoading(true);
        setDisableButton(true);
        document.body.classList.add('disable-scroll');

        const dashboardDetails = await getDashboardDetails();
        const getPropertiesResponse = await getExistingProperties();

        setTotalPropertiesCount(dashboardDetails.data.total_properties);
        setTotalVisitsCount(dashboardDetails.data.total_visits);
        setTotalDedicatedPhoneCount(dashboardDetails.data.total_dedicated_phone_numbers);
        setExistingProperties(getPropertiesResponse.data.property_list);
        setCurrentDeleteCount(getPropertiesResponse.data.delete_count);
      } catch (error) {
        if (error === null || error.code === "ERR_NETWORK") {
          notifyError("Website is under maintenance. Please try again later.");
        } else {
          const responseStatus = error.response?.status;
  
          if (responseStatus === 401) {
            notifyError("Unauthorized. Please try again.");
          } else {
            notifyError("Something went wrong. Please try again later.");
          }
        }
      } finally {
        setLoading(false);
        setDisableButton(false);

        document.body.classList.remove('disable-scroll');
      }
    }

    getDetails();  
  }, []);

  useEffect(() => {
    const query = window.location.search;
    const params = new URLSearchParams(query);

    const errorCode = params.get("error");
    const op = params.get("op");
    const success = params.get("success");

    if (errorCode && op) {
      if (op === "ep") {
        if (errorCode === "e500") {
          notifyError("Unable to fetch property details. Please try again later.");
        } else if (errorCode ==="e401") {
          notifyError("Unauthorized. Please try again later.");
        } else {
          notifyError("Unable to edit property. Please try again later.")
        }
      } 

      window.history.replaceState({}, document.title, window.location.pathname);
    } else if (success && op) {
      if (op === "ep") {
        notifySuccess("Property details successfully updated.");
      } else if (op === "dp") {
        notifySuccess("Property successfully deleted.");
      }

      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, []);

  const handleToggle = (index, isOpen) => {
    setOpenDropdownIndex(isOpen ? index : null);
  };

  const handleClose = () => {
    setShowDeletePropertyModal(false);
  };

  const handleShowDeletePropertyModal = () => {
    setShowDeletePropertyModal(true);
  }

  //todo: Remove states
  const navigateToTimeLock = () => {
    navigate("/properties/add-timelock", { state: { callboxPhone: currentProperty.callbox_phone_raw } });
  };

  // todo: remove states
  const navigateToEditTimelock = () => {
    navigate("/properties/edit-timelock", { state: { callboxPhone: currentProperty.callbox_phone } });
  };

  const navigateToEditProperty = () => {
    navigate("/properties/edit", { state: { callboxPhone: currentProperty.callbox_phone_raw } });
  };

  const handleShow = () => {
    setShowSidebar(!showSidebar);
  };

  const statsData = [
    {
      text: "Total Properties",
      count: totalPropertiesCount ? totalPropertiesCount + "" : "0",
      img: totalProperties,
      class: "totalProperties",
    },
    {
      text: "Total Visits",
      count: totalVisitsCount ? totalVisitsCount + "" : "0",
      img: totalVisits,
      class: "totalVisits",
    },
    {
      text: "Dedicated Phone Numbers",
      count: totalDedicatedPhoneCount ? totalDedicatedPhoneCount + "" : "0",
      img: phone,
      class: "phone",
    },
  ];

  return (
    <div className="d-lg-flex">
      <div>
        <DashboardSidebar active="properties" showSidebar={showSidebar} handleShow={handleShow}></DashboardSidebar>
      </div>
      <div className="w-100">
        <Topbar handleShow={handleShow} firstName={userFirstName} />
        <div className="properties-content">
          <div className="properties-stats">
            <h5 className="heading-5 heading-5-semi-bold">Stats</h5>
            <Row className="justify-content-center">
              {statsData.map((stat) => (
                <Col md={4} sm={6}>
                  <div className={`stats ${stat.class}`}>
                    <div className="stats-text">
                      <p className="text">{stat.text}</p>
                    </div>
                    <div className="stats-image">
                      <h2 className="count heading-1 heading-1-semi-bold">
                        {stat.count}
                      </h2>
                      <div className="img">
                        <Image src={stat.img} />
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <div className="properties">
            <div className="properties-heading">
              <h5 className="heading-5 heading-5-semi-bold">Properties</h5>
              <Nav.Link
                className="add-property-btn"
                as={Link}
                to="/add-property"
                disabled={disableButton}
              >
                <Image src={addPropertyIcon} />
                Add Property
              </Nav.Link>
            </div>
            <div className="properties-boxes">
              {totalPropertiesCount > 0 ? 
                <Row className="justify-content-center">
                  {existingProperties.map((prop, index) => (
                    <Col md={4} sm={6} key={index}>
                      <div className="box">
                        <div className="box-top">
                          <div className={`icon ${prop.property_type === "apt" ? "Apartment" : (prop.property_type === "house" ? "House" : "Condo")}`}>
                            <Image src={prop.property_type === "apt" ? apartment : (prop.property_type === "house" ? house : condo)} />
                            <p>{prop.property_type === "apt" ? "Apartment" : (prop.property_type === "house" ? "House" : "Condo")}</p>
                          </div>
                          <div className="dots">
                            <NavDropdown
                              title={
                                <>
                                  <Image src={dotsMenu} className="ms-1" />
                                </>
                              }
                              menuVariant="dark"
                              show={openDropdownIndex === index}
                              onToggle={(isOpen) => {
                                setCurrentProperty(prop);
                                handleToggle(index, isOpen)}}
                            > 
                              <NavDropdown.Item
                                className="edit-property"
                                onClick={navigateToEditProperty}
                                disabled={disableButton}
                              >
                                <span>
                                  <Image src={editproperty} className="me-2" />
                                </span>
                                Edit Property
                              </NavDropdown.Item>
                              { prop.is_timelock_active && 
                                <NavDropdown.Item
                                  className="add-timelock"
                                  onClick={navigateToEditTimelock}
                                  disabled={disableButton}
                                >
                                  <span>
                                    <Image src={addtimelock} className="me-2"></Image>
                                    Edit TimeLock
                                  </span>
                                </NavDropdown.Item>
                              }
                              { !prop.is_timelock_active && 
                                <NavDropdown.Item
                                  className="add-timelock"
                                  onClick={navigateToTimeLock}
                                  disabled={disableButton}
                                >
                                  <span>
                                    <Image src={timelock} className="me-2"></Image>
                                    Add TimeLock
                                  </span>
                                </NavDropdown.Item>
                              }
                              <NavDropdown.Item
                                className="delete"
                                onClick={handleShowDeletePropertyModal}
                                disabled={disableButton}
                              >
                                <span>
                                  <Image src={trash} className="me-2" />
                                </span>
                                Delete Property
                              </NavDropdown.Item>
                            </NavDropdown>
                          </div>
                        </div>
                        <h5 className="heading-6">{prop.property_name}</h5>
                        <p className="count">{prop.callbox_phone}</p>
                        <p className="count">Digits to be pressed: {prop.callbox_digits}</p>
                      </div>
                    </Col>
                  ))}
                </Row>
                : <div className="d-flex w-100 justify-content-center placeholder-box">
                    <p className="text-center w-100 mt-5 placeholder-text-main">You have not added any properties.</p>
                  </div>
              }
              <DeletePropertyModal
                propertyDeleteLimit={deletePropertyLimit}
                propertyDeleteCount={currentDeleteCount}
                selectedProperty={currentProperty}
                showModal={showDeletePropertyModal}
                closeModal={handleClose}
              > 
              </DeletePropertyModal>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loading></Loading>}
      <ToastContainer/>
    </div>
  );
};

export default Properties;
