import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes, Navigate } from "react-router-dom";

import Home from "./pages/home";
import SignUp from "./pages/signup";
import Login from "./pages/login";
import Profile from "./pages/profile";
import DashboardChangePassword from "./pages/changePassword";
import Subscription from "./pages/subscription";
import Dashboard from "./pages/dashboard";
import PropertiesRouter from "./components/shared/PropertiesRouter";
import ContactUs from "./pages/contactUs";
import PaymentSuccess from "./pages/paymentSuccess";
import EmailVerification from "./pages/emailVerification";
import ForgotPassword from "./pages/forgotPassword";
import { AuthContextProvider } from "./components/shared/AuthContext";
import ProtectedRoutes from "./components/shared/ProtectedRoutes";

function App() {
  return (
    <AuthContextProvider>
      <Routes>
        <Route path="/" exact element={<ProtectedRoutes accessBy="non-auth"><Home></Home></ProtectedRoutes>} />
        <Route path="/signup" element={<ProtectedRoutes accessBy="non-auth"><SignUp></SignUp></ProtectedRoutes>} />
        <Route path="/login" element={<ProtectedRoutes accessBy="non-auth"><Login></Login></ProtectedRoutes>} />
        <Route path="/dashboard" element={<ProtectedRoutes accessBy="auth-verified"><Dashboard></Dashboard></ProtectedRoutes>} />
        <Route path="/subscription" element={<ProtectedRoutes accessBy="auth-verified"><Subscription></Subscription></ProtectedRoutes>} />
        <Route path="/settings" element={<ProtectedRoutes accessBy="auth-verified"><Profile></Profile></ProtectedRoutes>} />
        <Route path="/change-password" element={<ProtectedRoutes accessBy="auth-verified"><DashboardChangePassword></DashboardChangePassword></ProtectedRoutes>} />
        <Route path="/properties" element={<ProtectedRoutes accessBy="auth-verified"><PropertiesRouter page="property-manage"></PropertiesRouter></ProtectedRoutes>} />
        <Route path="/contact-us" element={<ProtectedRoutes accessBy="auth-verified"><ContactUs></ContactUs></ProtectedRoutes>} />
        <Route path="/payment-success" element={<ProtectedRoutes accessBy="auth-verified"><PaymentSuccess></PaymentSuccess></ProtectedRoutes>} />
        <Route path="/email-verification" element={<ProtectedRoutes accessBy="auth-nonverified"><EmailVerification></EmailVerification></ProtectedRoutes>} />
        <Route path="/add-property" element={<ProtectedRoutes accessBy="auth-verified"><PropertiesRouter page="property-add"></PropertiesRouter></ProtectedRoutes>} />
        <Route path="/forgot-password" element={<ProtectedRoutes accessBy="non-auth"><ForgotPassword></ForgotPassword></ProtectedRoutes>}></Route>
        <Route path="/properties/add-timelock" element={<ProtectedRoutes accessBy="auth-verified"><PropertiesRouter page="timelock-add"></PropertiesRouter></ProtectedRoutes>}></Route>
        <Route path="/properties/edit-timelock" element={<ProtectedRoutes accessBy="auth-verified"><PropertiesRouter page="timelock-edit"></PropertiesRouter></ProtectedRoutes>}></Route>
        <Route path="/properties/edit" element={<ProtectedRoutes accessBy="auth-verified"><PropertiesRouter page="property-edit"></PropertiesRouter></ProtectedRoutes>}></Route>
        <Route path="*" element={<Navigate to="/" replace />}></Route>
      </Routes>
    </AuthContextProvider>
  );
}

export default App;
