import React from "react";
import { Image } from "react-bootstrap";

const CustomToggler = React.forwardRef(({ onClick, iconClass, clas }, ref) => (
  <div
    ref={ref}
    onClick={onClick}
    style={{ cursor: "pointer" }}
    className="custom-toggler z-2 position-relative"
  >
    <Image src={iconClass} className={clas} />
  </div>
));

export default CustomToggler;
