import DashboardSidebar from "../components/sections/dashboard/sidebar";

import "../assets/scss/common.scss";
import "../assets/scss/pages/password.scss";

import { useState, useContext } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import back from "../assets/images/back.svg";
import Btn from "../components/templates/button";
import AuthContext from "../components/shared/AuthContext";
import Loading from "../components/shared/Loading";
import TurnstileWidget from "../components/shared/TurnstileWidget";

const DashboardChangePassword = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [disableBackButton, setDisableBackButton] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [changePasswordFields, setChangePasswordFields] = useState({});
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const { changePassword } = useContext(AuthContext);

  const navigate = useNavigate();
  const notifyError = (errorMessage) => toast.error(errorMessage);

  const goBack = () => {
    navigate(-1);
  };

  const passwordChange = (field, value) => {
    setChangePasswordFields({
      ...changePasswordFields,
      [field]: value
    });

    if (value === "")
      setErrorText("");
    else
     setErrorText(handlePasswordValidation(value));
  };

  const confirmPasswordChange = (field, value) => {
    setChangePasswordFields({
      ...changePasswordFields,
      [field]: value
    });
  }

  const handlePasswordValidation = (password) => {
    var capitalLetterRegex = /[A-Z]/;
    var specialCharacterRegexPwd = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (password.length === 0)
      return "";
    else if (password.length < 8) 
      return "Password should be at least 8 characters long";
    else if (!capitalLetterRegex.test(password))
      return "Password should contain at least 1 capital letter";
    else if (!specialCharacterRegexPwd.test(password))
      return "Password should contain at least 1 special character";
    else 
      return "";
  };

  const handleChangePasswordSubmit = async (e) => {
    const formFields = {...changePasswordFields};
    
    try {
      e.preventDefault();
      
      if (handleChangePasswordValidation()) {
        const password = formFields['password'];
        const confirmPassword = formFields['confirm-password'];

        const payload = {
          new_password: password,
          confirm_new_password: confirmPassword,
          is_captcha_verified: isCaptchaVerified
        };

        setLoading(true);
        setDisableButton(true);
        setDisableBackButton(true);

        document.body.classList.add('disable-scroll');

        await changePassword(payload);
        setIsCaptchaVerified(false);

        navigate("/settings?passwordChanged=true");
    }
    } catch (e) {
      if (e === null || e.code === "ERR_NETWORK") {
        notifyError("Website is under maintenance. Please try again later.");
      } else {
        const responseStatus = e.response?.status;

        if (responseStatus === 401) {
          notifyError("Unauthorized. Please try again.");
        } else if (responseStatus === 500 || responseStatus === 404) {
          notifyError("Website is under maintenance. Please try again later.");
        } else if (responseStatus === 400) {
          notifyError("Invalid request. Please check your input.");
        } else if (responseStatus === 403) {
          notifyError("Your new password can't be the same as your old password.");
        } else if (responseStatus === 406) {
          notifyError("Turnstile is not validated.");
        } else if (responseStatus === 412) {
          notifyError("Passwords are not equal.")
        } else {
          notifyError("Website is under maintenance. Please try again later.");
        }
      }
    } finally {
        setLoading(false);
        setDisableButton(true);
        setDisableBackButton(false);

        document.body.classList.remove('disable-scroll');

        setTimeout(() => {
          setDisableButton(false);
        }, 2000);
    }
  };

  const handleChangePasswordValidation = () => {
    var specialCharacterRegexPwd = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    var capitalLetterRegex = /[A-Z]/;

    const formFields = {...changePasswordFields};

    let formIsValid = true;

    if (!formFields['password'] || !formFields['confirm-password']) {
      formIsValid = false;
      notifyError("Password can't be empty!");
    } else if (formFields['password'].length < 8) {
      formIsValid = false;
      notifyError("Password must be at least 8 characters long!");
    } else if (formFields['password'].length > 300) {
      formIsValid = false;
      notifyError("Password is too long!");
    } else if (!capitalLetterRegex.test(formFields["password"])) {
      formIsValid = false;
      notifyError("Password must have at least 1 capital letter!");
    } else if (!specialCharacterRegexPwd.test(formFields["password"])) {
      formIsValid = false;
      notifyError("Password must have at least 1 special character! (!@#$%^&*)");
    } else if (formFields['confirm-password'] && (formFields['password'] != formFields['confirm-password'])) {
      formIsValid = false;
      notifyError("Passwords do not match!");
    } else if (!isCaptchaVerified) {
      formIsValid = false;
      notifyError("Turnstile is not validated.");
    }

    return formIsValid;
  };

  const handleShow = () => {
    setShowSidebar(!showSidebar);
  };

  const handleTurnstileChange = (value) => {
    setIsCaptchaVerified(value);
  }
  
  return (
    <div className="dashboard-body">
      <div className="d-lg-flex">
        <div>
          <DashboardSidebar
            active="password"
            showSidebar={showSidebar}
            handleShow={handleShow}
          />
        </div>
        <div className="w-100">
          <div>
            <div className="change-password">
              <div className="password">
                <h5 className="heading-5 heading-5-semi-bold">
                  Change Password
                </h5>
                <div className="back-btn p-lg-0 pt-4 pb-2">
                  <Button className="back-btn" onClick={goBack} disabled={disableBackButton}>
                    <div className="back-icon me-2">
                      <Image src={back} />
                    </div>
                    Back
                  </Button>
                </div>
                <div className="change-password-form">
                  <div className="form">
                    <Form>
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="New Password"
                        className="mb-3"
                        onChange={e => passwordChange('password', e.target.value)}
                        value={changePasswordFields['password']}
                      />
                      {errorText && <p className="paragraph-small paragraph-small-regular text-center error-text my-2">{errorText}</p>}
                      <Form.Label>Confirm New Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Confirm New Password"
                        className="mb-3"
                        onChange={e => confirmPasswordChange('confirm-password', e.target.value)}
                        value={changePasswordFields['confirm-password']}
                      />
                      <div className="mt-3 mb-3">
                        <TurnstileWidget onValueChange={handleTurnstileChange}></TurnstileWidget>
                      </div>                      
                      <Btn text="Update Password" class="primary-btn" onClick={handleChangePasswordSubmit} disabled={disableButton} />
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {loading && <Loading></Loading>}
    </div>
  );
};

export default DashboardChangePassword;
