import mobLogo from "../../../assets/images/dashboard/dashboard-mobile.svg";
import dashboardHamburger from "../../../assets/images/dashboard/dashboard-hamburger.svg";

import { Button, Image, NavDropdown } from "react-bootstrap";
import up from "../../../assets/images/dashboard/up.svg";
import down from "../../../assets/images/dashboard/down.svg";
import setting from "../../../assets/images/dashboard/settings_svgrepo.com.svg";
import logout from "../../../assets/images/dashboard/logout.svg";
import { useContext, useState } from "react";
import AuthContext from "../../shared/AuthContext";
import Loading from "../../shared/Loading";

const Topbar = ({ handleShow, type, firstName }) => {

  const { signOutUser } = useContext(AuthContext);

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const handleToggle = (isOpen) => {
    setIsOpen(isOpen);
  };

  const signOutUserAccount = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      document.body.classList.add('disable-scroll');
      setDisableButton(true);

      await signOutUser();
    } catch (error) {
      console.log("Website is under maintenance. Please try again later.");
    } finally {
      setLoading(false);
      document.body.classList.remove('disable-scroll');
      window.location.reload();

      setTimeout(() => {
          setDisableButton(false);
      }, 2000);
    }
    
  }

  return (
      <div>
        <div className="mobile-navbar justify-content-lg-end">
          <Button onClick={handleShow} className="d-lg-none">
            <Image src={dashboardHamburger} />
          </Button>
          <div className="mobile-navabr-logo d-lg-none">
            <Image src={mobLogo} />
          </div>
          <div>
            <div className="mobile-navbar-dropdown">
              <div className="profile-dropdown d-flex justify-content-end">
                <NavDropdown
                    title={
                      <>
                        {firstName ? firstName : ""} <Image src={isOpen ? up : down} className="ms-1"/>
                      </>
                    }
                    menuVariant="dark"
                    show={isOpen}
                    onToggle={handleToggle}
                >

                  {(type!=='verification') ?
                      <NavDropdown.Item href="/settings" className="setting">
                        <span>
                          <Image src={setting} className="me-2"/>
                        </span>
                        Settings
                      </NavDropdown.Item>
                    : ''
                  }

                  <NavDropdown.Item disabled={disableButton} onClick={signOutUserAccount} className="logout">
                    <span>
                    <Image src={logout} className="me-2"/>
                    </span>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            </div>
          </div>
        </div>
        {loading && <Loading></Loading>}
      </div>
  );
};

export default Topbar;
