import "../assets/scss/common.scss";
import "../assets/scss/pages/dashboard.scss";

import { useState } from "react";
import Topbar from "../components/sections/dashboard/topbar";
import DashboardSidebar from "../components/sections/dashboard/sidebar";

const AccessDenied = ({ userFirstName }) => {
    const [showSidebar, setShowSidebar] = useState(false);

    const handleShow = () => {
        setShowSidebar(!showSidebar);
      };

    return (
        <div className="d-lg-flex">
            <div>
                <DashboardSidebar active="properties" showSidebar={showSidebar} handleShow={handleShow}></DashboardSidebar>
            </div>
            <div className="w-100">
                <Topbar handleShow={handleShow} firstName={userFirstName} />
                <div className="dashboard-content">
                    <h3 className="heading-4 heading-4-semi-bold text-center">This page is only accessible to paid subscribers!</h3>
                </div>
            </div>
        </div>
    );
};

export default AccessDenied;